// Import dependencies
import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import * as cocossd from "@tensorflow-models/coco-ssd";

import { Col, Container, Row } from "react-bootstrap";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Navbar from "./components/navbar";

//Import MUI navbar

// import components from Mui UI for displaying interactions
import Alert from "@mui/material/Alert";

// 1. TODO - Import required model here
// e.g. import * as tfmodel from "@tensorflow-models/tfmodel";
import Webcam from "react-webcam";

import "react-camera-ios/build/styles.css";

import styled from "styled-components";

import "./App.css";
// 2. TODO - Import drawing utility here
// e.g. import { drawRect } from "./utilities";
import { drawRect } from "./utilities";

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const videoConstraints = {
    facingMode: { exact: "environment" },
  };

  // Main function
  const runCoco = async () => {
    // 3. TODO - Load network
    // e.g. const net = await cocossd.load();
    const net = await cocossd.load();

    //  Loop and detect hands
    setInterval(() => {
      detect(net);
    }, 10);
  };

  const detect = async (net) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // 4. TODO - Make Detections
      // e.g. const obj = await net.detect(video);
      const obj = await net.detect(video);
      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");

      // 5. TODO - Update drawing utility
      // drawSomething(obj, ctx)
      drawRect(obj, ctx);
    }
  };

  useEffect(() => {
    runCoco();
  }, []);
  const screenHeight = window.innerHeight;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
  };

  const Scrollbar = styled.div`
    display: flex;
    width: 100%;
  `;

  const Scrollbar_slide = styled.div`
    overflow-x: scroll;
    display: flex;
  `;

  const Scrollbar_item = styled.div`
    margin: 20px;
  `;

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <Webcam
          // videoConstraints voor rear camera telefoon
          videoConstraints={videoConstraints}
          ref={webcamRef}
          muted={true}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 8,
            width: "100%",
            height: `100vh`,
            objectFit: "cover",
          }}
        />
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            width: "100%",
            height: `100vh`,
          }}
        />

        <Scrollbar
          style={{
            position: "absolute",
            bottom: "56px",
            zIndex: 10,
          }}
        >
          <Scrollbar_slide>
            <Scrollbar_item>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Ruby Holmes
                  </Typography>
                  <div className="containerslider">
                    <div
                      style={{
                        backgroundColor: "#EFF0F3",
                        borderRadius: "8px",
                        width: "100%",
                        height: "100%",
                        margin: "5px",
                      }}
                    >
                      <h4
                        style={{
                          margin: "10px",
                        }}
                      >
                        Radius
                      </h4>
                      <p
                        style={{
                          margin: "10px",
                        }}
                      >
                        5km
                      </p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EFF0F3",
                        borderRadius: "8px",
                        width: "100%",
                        height: "100%",
                        margin: "5px",
                      }}
                    >
                      <h4
                        style={{
                          margin: "10px",
                        }}
                      >
                        Veilig?
                      </h4>
                      <p
                        style={{
                          margin: "10px",
                        }}
                      >
                        Ja
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Scrollbar_item>
            <Scrollbar_item>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Tina Sanders
                  </Typography>
                  <div className="containerslider">
                    <div
                      style={{
                        backgroundColor: "#EFF0F3",
                        borderRadius: "8px",
                        width: "100%",
                        height: "100%",
                        margin: "5px",
                      }}
                    >
                      <h4
                        style={{
                          margin: "10px",
                        }}
                      >
                        Radius
                      </h4>
                      <p
                        style={{
                          margin: "10px",
                        }}
                      >
                        7km
                      </p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EFF0F3",
                        borderRadius: "8px",
                        width: "100%",
                        height: "100%",
                        margin: "5px",
                      }}
                    >
                      <h4
                        style={{
                          margin: "10px",
                        }}
                      >
                        Veilig?
                      </h4>
                      <p
                        style={{
                          margin: "10px",
                        }}
                      >
                        Nee
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Scrollbar_item>
          </Scrollbar_slide>
        </Scrollbar>

        <Button
          onClick={handleOpen}
          variant="contained"
          style={{
            position: "absolute",
            bottom: "25px",
            zIndex: 10,
            width: "85%",
            height: "40px",
            backgroundColor: "#DB9F1D",
            fontWeight: "bold",
            textTransform: "inherit",
          }}
          color="success"
        >
          Ik ben veilig
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                fontWeight: "bolder",
                fontSize: "1.5rem",
              }}
            >
              Situatie veilig?
            </Typography>
            <Typography id="modal-modal-description">
              Heeft u zichzelf in veiligheid gebracht?
            </Typography>
            <div
              className="buttonsmodal"
              style={{
                marginTop: "10px",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                style={{
                  zIndex: 10,
                  height: "40px",
                  backgroundColor: "#CCDB1D",
                  fontWeight: "bold",
                  marginTop: "10px",
                  boxShadow: "none",
                  textTransform: "inherit",
                  width: "48%",
                }}
                color="success"
              >
                Veilig
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                style={{
                  zIndex: 10,
                  height: "40px",
                  backgroundColor: "#FF0000",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginLeft: "10px",
                  boxShadow: "none",
                  textTransform: "inherit",
                  width: "48%",
                }}
                color="success"
              >
                Niet veilig
              </Button>
            </div>
          </Box>
        </Modal>
        {/* <Alert severity="warning"
          style={{
          position: "absolute",
          zIndex: 10,
        }}>
         It is time to evacuate
         </Alert> */}
      </header>
    </div>
  );
}

export default App;
