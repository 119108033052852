let arrowDrawn = false;
let arrowTimeout;

export const drawRect = (detections, ctx) => {
  let imgCanvas = document.createElement("canvas");
  imgCanvas.width = ctx.canvas.width;
  imgCanvas.height = ctx.canvas.height;
  let imgCtx = imgCanvas.getContext("2d");

  detections.forEach((prediction) => {
    if (prediction["class"] !== "bottle") {
      if (arrowDrawn) {
        clearTimeout(arrowTimeout);
        imgCtx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        arrowDrawn = false;
      }
      return;
    }

    let image = new Image();
    image.src =
      "https://media.discordapp.net/attachments/777835800649793556/1066720463289602169/Arrow_png_image_final.png";

    if (!arrowDrawn) {
      const [x, y, width, height] = prediction["bbox"];

      const aspectRatio = image.naturalWidth / image.naturalHeight;
      let imageWidth = width;
      let imageHeight = imageWidth / aspectRatio;

      if (imageHeight > height) {
        imageHeight = height;
        imageWidth = imageHeight * aspectRatio;
      }

      imgCtx.drawImage(image, x, y, imageWidth, imageHeight);
      ctx.drawImage(imgCanvas, 0, 0);
      arrowDrawn = true;
      arrowTimeout = setTimeout(() => {
        imgCtx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        arrowDrawn = false;
      }, 1000);
    }
  });

  detections.forEach((prediction) => {
    if (prediction["class"] !== "person") {
      if (arrowDrawn) {
        clearTimeout(arrowTimeout);
        imgCtx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        arrowDrawn = false;
      }
      return;
    }

    let image = new Image();
    image.src =
      "https://media.discordapp.net/attachments/777835800649793556/1066706835333189732/person.png";

    if (!arrowDrawn) {
      const [x, y, width, height] = prediction["bbox"];

      const aspectRatio = image.naturalWidth / image.naturalHeight;
      let imageWidth = width;
      let imageHeight = imageWidth / aspectRatio;

      if (imageHeight > height) {
        imageHeight = height;
        imageWidth = imageHeight * aspectRatio;
      }

      imgCtx.drawImage(image, x, y, imageWidth, imageHeight);
      ctx.drawImage(imgCanvas, 0, 0);
      arrowDrawn = true;
      arrowTimeout = setTimeout(() => {
        imgCtx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        arrowDrawn = false;
      }, 1000);
    }
  });
};
